<template>
  <div>
    <div class="table">
      <div>
        <div
            style="display: flex; flex-direction: row; width: 100%; gap: 10px;"
        >
          <div
              v-for="header in processedHeaders.filter(item => typeof tableData[0][item.value] === 'string' || ['Договор', 'Юр.лицо'].includes(item.text))"
              :key="header.text"
              style="width: 100%;"
          >
            <v-container fluid>
              <v-combobox
                  v-model="model[header.value]"
                  :search="search[header.value]"
                  :hide-no-data="true"
                  :items="tableData.map(item => item[header.value])"
                  :label="'Фильтровать по ' + header.text"
                  chips
                  density="compact"
                  hide-selected
                  multiple
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-container>
          </div>
          <v-btn @click="exportToExcel" color="primary">
            Экспорт в Excel
          </v-btn>
        </div>
        <v-data-table v-model="selected" @input="enterSelect()" locale="ru-RU" checkbox-color="#FEE600"
                      :loading="loading" :page.sync="page" :custom-sort="customSort" :headers="processedHeaders"
                      :show-select="!hideselect" :items="tableData" :itemsPerPage="localPerPage"
                      @page-count="pageCount = $event"
                      hide-default-footer no-data-text="Нет данных" item-key="id" class="elevation-1"
                      loading-text="Загружаем данные таблицы">

          <template #progress>
            <v-progress-linear indeterminate color="green"></v-progress-linear>
          </template>
        </v-data-table>

        <div class="pagination-wrapper">
          <v-pagination color="#E9EAEA" class="my-4" :total-visible="7" v-model="page" :length="pageCount">
          </v-pagination>
          <div class="items-per-page">
            <span>Элементов на странице: {{ localPerPage }}</span>
            <v-select v-model="localPerPage" :items="itemsPerPageOptions" dense outlined hide-details
                      class="per-page-select" @change="handlePerPageChange"></v-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import * as XLSX from "xlsx";

export default {
  props: ['headers', 'items', 'hideselect', 'data', 'perPage', 'callback_edit', 'callback_stop', 'callback_refresh',
    'callback_view', 'callback_play', 'callback_delete', 'server', 'pageCount_server', 'loading', 'callback_draw', 'page_type'
  ],
  data() {
    return {
      page: 1,
      selected: [],
      localPerPage: 25,
      itemsPerPageOptions: [10, 25, 50, 100],
      SumIgnoreColumns: ['Процент доставки'],
      AvgIgnoreColumns: [''],
      selectedFilters: {},
      filters: {},
      model: {},
      search: {}
    }
  },
  watch: {
    options: {
      deep: true,
    },
    data: {
      immediate: true
    }
  },
  beforeDestroy() {
    let data = {
      hide: true,
      data: [],
      page_type: "none",
      cb_refresh: false,
      cb_clear: false
    }
    this.$store.commit('notification/setFooterState', data)
  },
  methods: {
    exportToExcel() {
      const filteredData = this.data.filter(row => {
        return Object.keys(this.model).every(key => {
          const filterValues = this.model[key];
          return !filterValues.length || filterValues.includes(row[key]);
        });
      });

      if (!filteredData.length) {
        alert("Нет данных для экспорта.");
        return;
      }

      const headersMap = this.processedHeaders.reduce((acc, header) => {
        acc[header.value] = header.text;
        return acc;
      }, {});

      const formattedData = filteredData.map(row => {
        const newRow = {};
        Object.keys(row).forEach(key => {
          if (headersMap[key]) {
            newRow[headersMap[key]] = row[key];
          }
        });
        return newRow;
      });

      const worksheet = XLSX.utils.json_to_sheet(formattedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Все данные");

      XLSX.writeFile(workbook, "exported_data.xlsx");
    },
    customSort(items, index, isDesc) {

      items = items.filter(item => {
        return Object.keys(this.model).every(key => {
          return this.model[key].length === 0 || this.model[key].includes(item[key]);
        });
      });

      const totalRow = items.find(item => item[this.headers[0].value] === 'ИТОГО');
      const averageRow = items.find(item => item[this.headers[0].value] === 'Ср. Знач.');

      const regularItems = items.filter(item =>
          item[this.headers[0].value] !== 'ИТОГО' && item[this.headers[0].value] !== 'Ср. Знач.'
      );

      const desc = isDesc === "true";

      if (regularItems.length > 0) {
        regularItems.sort((a, b) => {
          if (index === "target_price") {
            let priceA = a["target_on"] && a.target > 0 ? a.target_rate / a.target : 0;
            let priceB = b["target_on"] && b.target > 0 ? b.target_rate / b.target : 0;

            return desc ? priceB - priceA : priceA - priceB;
          }

          const valA = a[index] ?? "";
          const valB = b[index] ?? "";

          if (typeof valA === "number" && typeof valB === "number") {
            return desc ? valB - valA : valA - valB;
          } else {
            return desc ? valB.localeCompare(valA) : valA.localeCompare(valB);
          }
        });
      }

      return [...regularItems, ...[totalRow, averageRow].filter(Boolean)];
    },

    calculateTotalRow() {
      let totalRow = {}

      totalRow[this.headers[0].value] = 'ИТОГО';

      this.headers.forEach(header => {
        if (this.isNumericColumn(header) && !this.SumIgnoreColumns.includes(header.text)) {
          totalRow[header.value] = this.data.reduce((sum, item) => {
            const value = parseFloat(item[header.value]) || 0;
            return sum + value
          }, 0);
        } else if (this.SumIgnoreColumns.includes(header.text)) {
          totalRow[header.value] = "Без подсчета";
        } else {
          if (this.headers[0].value !== header.value) {
            totalRow[header.value] = "Не число";
          }
        }
      });

      Object.keys(totalRow).map(column => {
        totalRow[column] = typeof totalRow[column] === 'number' ? +(totalRow[column].toFixed(2)) : totalRow[column]
      })
      return totalRow;
    },

    calculateAverageRow() {
      let averageRow = {}

      averageRow[this.headers[0].value] = 'Ср. Знач.';

      // Вычисляем средние значения по каждому столбцу
      this.headers.forEach(header => {
        if (this.isNumericColumn(header) && !this.AvgIgnoreColumns.includes(header.text)) {
          const sum = this.data.reduce((acc, item) => {
            const value = parseFloat(item[header.value]) || 0;
            return acc + value;
          }, 0);
          averageRow[header.value] = (sum / this.data.length).toFixed(2);
        }
      });

      Object.keys(averageRow).map(column => {
        averageRow[column] = typeof averageRow[column] === 'number' ? +(averageRow[column].toFixed(2)) : averageRow[column]
      })

      return averageRow;
    },

    isNumericColumn(header) {
      // Проверяем первое непустое значение в колонке
      const firstItem = this.data.find(item => item[header.value] !== undefined && item[header.value] !== null);
      if (!firstItem) return false;

      const value = firstItem[header.value];

      // Проверяем является ли значение числом или строкой с числом
      if (typeof value === 'number') return true;
      if (typeof value === 'string') {
        // Исключаем телефонные номера (содержат более 5 цифр подряд)
        if (/\d{6,}/.test(value)) return false;

        // Проверяем, можно ли преобразовать в число
        const parsed = parseFloat(value.replace(/[^-0-9.]/g, ''));
        return !isNaN(parsed);
      }
      return false;
    },

    handlePerPageChange() {
      this.page = 1; // Сброс на первую страницу
      this.pageCount = Math.ceil(this.data.length / this.localPerPage);
    },
    getHeaderWithTotal(header) {
      if (!this.isNumericColumn(header.value) || !this.data || this.data.length === 0) {
        return header.text;
      }
      if (['Процент доставки', 'Процент кликов'].includes(header.text)) {
        return header.text;
      }

      const total = this.calculateColumnTotal(header.value);
      return `${header.text} (${total})`;
    },

    calculateColumnTotal(column) {
      if (!this.data || !this.data.length) return 0;

      const sum = this.data.reduce((acc, item) => {
        let value = item[column];

        // Если значение - строка, извлекаем из неё числа
        if (typeof value === 'string') {
          value = parseFloat(value.replace(/[^-0-9.]/g, ''));
        }

        return acc + (isNaN(value) ? 0 : value);
      }, 0);

      // Форматируем число с учётом локали
      return new Intl.NumberFormat('ru-RU', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0
      }).format(sum);
    },
    clearSelected() {
      this.selected = []
    },
    async copy(text) {
      await navigator.clipboard.writeText(text)
    },

    enterSelect() {
      if (this.selected.length > 0) {
        let data = {
          hide: false,
          data: this.selected,
          page_type: this.page_type,
          cb_refresh: this.callback_refresh,
          cb_clear: this.clearSelected
        }

        this.$store.commit('notification/setFooterState', data)

      } else {
        let data = {
          hide: true,
          data: [],
          page_type: "none",
          cb_refresh: false,
          cb_clear: false
        }
        this.$store.commit('notification/setFooterState', data)
      }
    },
  },
  computed: {
    filteredData() {
      return this.data.filter(item => {
        return Object.keys(this.filters).every(key => {
          if (!this.filters[key] && (!this.selectedFilters[key] || this.selectedFilters[key].length === 0)) return true;
          const textMatch = !this.filters[key] || String(item[key]).toLowerCase().includes(this.filters[key].toLowerCase());
          const listMatch = !this.selectedFilters[key] || this.selectedFilters[key].length === 0 || this.selectedFilters[key].includes(item[key]);
          return textMatch && listMatch;
        });
      });
    },
    tableData() {
      // Получаем обычные данные для текущей страницы
      const start = (this.page - 1) * this.localPerPage;
      const end = start + this.localPerPage - 2; // Вычитаем 2 для итоговых строк
      const paginatedData = this.data.slice(start, end);

      // Добавляем итоговые строки к пагинированным данным
      return [
        ...paginatedData,
        this.calculateTotalRow(this.data), // Считаем итоги по ВСЕМ данным
        this.calculateAverageRow(this.data) // Считаем среднее по ВСЕМ данным
      ];
    },

    // Обновляем подсчет количества страниц
    pageCount() {
      return Math.ceil((this.data.length) / (this.localPerPage - 2)); // Вычитаем 2 для учета итоговых строк
    },
    processedHeaders() {
      return this.headers.map(header => {
        return {
          ...header,
          text: header.text
        }
      });
    },
  }
}
</script>
<style lang="scss">
.pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}

.items-per-page {
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    color: #666;
    font-size: 14px;
  }

  .per-page-select {
    width: 80px;
  }
}

.v-select.per-page-select {
  .v-input__control {
    min-height: 32px;
  }

  .v-input__slot {
    min-height: 32px;
  }
}


.record {
  width: 100%;
  text-align: center;
}

.table_text {
  max-width: 300px;
  width: 100%;
}

.operator_ico {
  display: flex;
  align-items: center;
}

.operator_ico > .icon {
  margin-right: 5px;
}

.hidden {
  display: none;
}

.table_text {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #2B2D33;
}

.num {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
}

.plus {
  color: #008E7D;
}

.delete_stop {
  color: #EE505A;
}

.delete_stop:hover {
  cursor: pointer;
}

.minus {
  color: #C74952;
}

.time {
  margin-right: 8px;
}

.input_wrap {
  width: 132px;
  height: 32px;
}

.props:hover {
  background: #E6F4FC;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.hover_actions:hover {
  cursor: pointer;
}

.props {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}

.auto {
  display: inline-block;
  background: #E1E0FE;
  border-radius: 4px;
  padding: 2px 6px;
  margin-right: 6px;
}

.status_wait {
  background: #dfe76b;
  padding: 4px 8px;
  display: inline-block;
  border-radius: 4px;
}

.status_stop,
.status_uncall {
  text-align: center;
  background: #FEE0E0;
  padding: 4px 8px;
  display: inline-block;
  border-radius: 4px;
}

.status_end,
.status_success {
  background: #CFF1E9;
  padding: 4px 8px;
  display: inline-block;
  border-radius: 4px;
}

.delete {
  font-size: 15px;
  color: #EE505A;
  cursor: pointer;
}

.v-list div {
  cursor: pointer;
}

.table {
  background: #fff;
  border-radius: 8px;
  padding: 20px 16px 32px 16px;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
  border-bottom: none !important;
}

.v-application .elevation-1 {
  box-shadow: none !important;;
}

.target_container {
  display: flex;
  align-items: flex-start;
  justify-content: left;
}

.target_des {
  display: inline-block;
}

.target {
  width: 15px;
  cursor: pointer;
  margin-left: 5px;
}

.v-data-table tfoot td {
  font-weight: bold;
  background-color: #f5f5f5;
  border-top: 2px solid #e0e0e0;
}

</style>